<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-S_re1-frontale-210614-DEF-1536x1044.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4">
                 <p style="font-size: 16px; color: black; font-weight: 400">
                   Fully automatic terminating machine for IDC Connector
                  </p>
                  <h1
                    style="color: black; font-weight: 800"
                    class="font-size-20 mb-3"
                  >
                    MAIN FEATURES
                  </h1>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Feeding system: up to 10 different wires, wire length freely programmable – Parallel and crossing wires as well as loops or one-side wire terminations are producible – Min. wire length = 150 mm (Loops), 55 mm (Free wire) – Max. wire length = 3000 mm (Loops), 1500 mm (Free wire) – Simultaneous processing connectors with different number of ways, coding versions etc. – Max Production configuration: 44 contact positions for Rast 2,5, 24 contact positions for Rast 5 – Connector loading system without machine stop – Terminating Unit with fine adjustment termination height – Test device for the correct wire position in the connector – Continuity Test (optional) – Coding station (optional) – Test device of the correct key cutting (optional) – Selective destroying unit of bad harness (optional) – User friendly HMI based on Windows O.S. – internet connection for remote assistance – Conveyor guide for good harnesses – Expulsion of bad harnesses – CE conformity
                  </p>
                </div>
                <br />
                <br />
                <br />
                <br />
                                        
    
                <p style="font-size: 14px; color: black; font-weight: 400">
                  Data and measurements are indicative and subject to change
                  without notice. Some particular types of cables may not be
                  machined even though they fall within the range of sections
                  indicated. KM Corporate will still be happy to carry out tests
                  and supply wire-worked samples
                </p>
              </div>
            </div>
            <!-- end row -->
            <br />
            <br />
            <br />
            <div class="row">
            <div class="col-5"></div>
            <div class="col-7">
            <button @click="file()" class="btn btn-primary" style="width:100%"><i class="fa fa-download"></i> Download The Technical Sheet</button>
            </div>
            </div>
            <br><br><br>
            <div class="row">
              <h1
                style="color: black; font-weight: 800; margin-left: 40px"
                class="font-size-20 mb-3"
              >
              Gallery
            
              </h1>
              
            </div>
             <br />

            <div class="row" style="margin-left:50px;">
             
              <vue-picture-swipe :items="items"></vue-picture-swipe>
            </div>
            <!-- end row -->
          
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
    Footer,

    
  },
  data(){
           return {
        items: [{
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-1-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-1-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900,
          alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-2-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-2-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-3-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-3-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-4-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-4-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-5-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-5-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-6-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-6-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-7-rev.1-210611-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Dettaglio-Intecon-S-7-rev.1-210611-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-S-_-Horizontal_Footprint-1-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-S-_-Horizontal_Footprint-1-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-S-_-Vertical_Footprint-1-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-S-_-Vertical_Footprint-1-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-KM-012-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-KM-012-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-KM-024-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-KM-024-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-KM-028-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-KM-028-150x150.jpg',
          w: 1200,
          h: 900
        },
      ]};

  },

methods: {
  file(){
    window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Intecon_S_Rev-19-01-Low.pdf";
   
  }
}
};
</script>